.credit-module {
  .btn-grp-credit {
    width: 100%;
    max-width: 700px;

    .btn {
      padding: 16px 2px;
      width: calc(100% / 3);
      font-size: 14px;
      color: #000;
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0;
      font-weight: 700;

    }

  }


  .credit-module-card {
    margin-top: 20px;
    min-height: 200px;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);



    .credit-head {
      background: #0e5768;
      padding: 1px 10px;
      color: #fff;
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;

    }
  }

  .btn-grp-credit-details {
    width: 100%;
    margin-left: 4px;

    .btn {
      padding: 12px 2px;
      width: calc(99.7% / 5);
      font-size: 14px;
      color: #000;
      border: 1px solid var(--line-color, rgba(0, 0, 0, 0.25));
      font-weight: 700;
      border-radius: 0;

      &:first-child {
        border-top-left-radius: 12px;
      }

      &:last-child {
        border-top-right-radius: 12px;
      }
    }
  }

  .details-body-credit {
    min-height: 200px;
    background: #fff;
    margin-top: -10px;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.25) // border
  }


  .dashed-border-blue {
    border-right: 1px dashed #A8C8FF;
    height: 80px;
    margin: auto;
    width: 3px;

  }

  .kyc-card-credit {
    margin-top: 10px;
    border: 1px solid #A8C8FF;
    // min-height: 200px;
    border-radius: 12px;
    height: 100%;
  }

  .opertion-table-credit {
    width: 100%;
    border-collapse: collapse;
    width: 97%;
    margin-left: auto;
    margin-right: auto;
  }

  .opertion-table-credit td {
    padding: 10px;
    width: 50%;
    border: 1px solid #A8C8FF;
    border-right: none;
  }

  .opertion-table-credit tr:nth-child(even) {
    background-color: #F1F7FF;
  }

  .opertion-table-credit tr:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    
  }

  .opertion-table-credit tr:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .download-btn {
    border-radius: 6px;
    border: 1px solid #1eb0d2;
    background: #fff;
    padding: 12px;
    // margin-top: 21px;
    width: max-content;
    color: #2B78FF;
    font-weight: 700;

  }

  .remove-rechart-line {
    .recharts-curve {
      display: none;
    }
  }

  .step-aadhar {
    cursor: pointer;
  }

  .credit-blue-box {
    width: 98%;
    margin-left: 10px;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid #A8C8FF;
    background: #F1F7FF;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .phocket-table-new {
    table {
      border: 1px solid var(--line-color, rgba(0, 0, 0, 0.25));
      border-radius: 15px;
    }
  }

  .crif-table-small {
    border-radius: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .crif-table-small table,
  .crif-table-small th,
  .crif-table-small td {
    border: 1px solid #DBE9FF;
    border-collapse: collapse;
  }

  .crif-table-small th,
  .crif-table-small td {
    padding: 5px;
    text-align: left;
  }

  .crif-table-small th {
    /* background: $teal; */
    /* color: $whitecolor; */
  }

  .crif-table-small tr:first-child {
    -webkit-border-top-left-radius: 10px;
    -webkit-border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .crif-table-small tr:last-child {
    -webkit-border-bottom-left-radius: 10px;
    -webkit-border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .internal-score-1 {
    border-radius: 6px;
    border: 0.5px solid var(--line-color, rgba(0, 0, 0, 0.25));
    background: var(--white, #FFF);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    margin-top: 20px;
    padding: 10px;
  }

  .scoring-box {
    padding: 10px;
    border: '1px solid '
  }

  .scoring-table {
    width: 100%;

    td {
      padding: 10px;
      width: 50%;
      // text-align: center;
      border: 1px solid#DBE9FF;
    }
  }

  tr:nth-child(even) {
    background-color: #F1F7FF;
  }


}

.working-history-table {
  width: 100%;
}

  .working-history-table td {
    padding: 10px;
    width: 50%;
    border: 1px solid #A8C8FF;
  
}